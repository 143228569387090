<template>
  <div>
    <titleBar
      :title="'Edit Profile'"
      :img="user?.avatar"
      back="auto"
      :showBackArrowDesktop="true"
      subtitle=""
      :centered="false"
      theme="white"
      :showSignup="false"
      containerClass="donotuse"
      :showDesktop="true"
      :showAfterScroll="0"
      :scrollTopOnClick="true"
      :inline="false"
      actionButtonStyle="round"
      actionButtonRoundBg="transparent"
      iconPack="fas"
    />

    <div class="sectionNA">
      <div class="containerNA NHAw800">
        <b-field horizontal label=" ">
          <header>
            <h2 class="title is-2">
              <span>Profile page </span>
            </h2>
          </header>
        </b-field>

        <hr />

        <!-- START-->

        <b-field horizontal label="Bio">
          <b-input
            type="textarea"
            placeholder="Hi!  I chat and I know things."
            v-model="user.bio"
            :loading="posting"
            :disabled="posting"
          ></b-input>
        </b-field>

        <b-field horizontal label=" Name   ">
          <b-input
            size="is-"
            placeholder="Samantha (bots will call you that)"
            v-model="user.name"
            :loading="posting"
            :disabled="posting"
          ></b-input>
        </b-field>
        <!-- 

        <b-field horizontal label="Tags">
          <b-taginput
            v-model="user.tags"
            ellipsis
            placeholder=" utility, dating, marketing"
            aria-close-label="Delete this tag"
          >
          </b-taginput>
        </b-field>-->

        <b-field horizontal>
          <b-switch v-model="user.verifiedbadge" type="is-warning">
            Verified badge
            <b-tooltip type="is-dark" multilined label="Display a blue tick next to your page name in listings.">
              <i class="fal fa-question-circle" aria-hidden="true"></i>
            </b-tooltip>
            <span class="tag is-warning is-light">Premium</span>
          </b-switch>
        </b-field>

        <!-- 
        <b-field horizontal>
          <b-switch v-model="user.showInListing" type="is-info">
            NSFW
            <b-tooltip
              type="is-dark"
              multilined
              label="If your content is not suitable for all audiences, you can mark it as NSFW. AI models aren't suited to generate explicit or inappropriate content. Circumventing this filter is not allowed."
            >
              <i class="fal fa-question-circle" aria-hidden="true"></i>
            </b-tooltip>
          </b-switch>
        </b-field>
         -->

        <b-field horizontal label="Website">
          <b-input
            size="is-"
            placeholder="https://myapp.com/developpers"
            v-model="user.website"
            :loading="posting"
            :disabled="posting"
            type="url"
          ></b-input>
        </b-field>

        <!-- select input for "profileStyle". toptions: mosaic, feed, examples, readme -->

        <b-field horizontal label="Default tab">
          <b-select v-model="user.profileStyle" :disabled="posting">
            <option value="auto">Automatic</option>
            <option value="mosaic">Mosaic</option>
            <option value="feed">Feed</option>
            <!--  
            <option value="examples">Examples</option> -->
            <option value="readme">Readme</option>
          </b-select>
        </b-field>

        <b-field horizontal label="Avatar URL ">
          <b-input
            size="is-"
            placeholder="https://myapp.com/img/logo.png"
            v-model="user.avatar"
            :loading="posting"
            :disabled="posting"
            type="url"
          ></b-input>
        </b-field>
        <b-field horizontal v-if="user.avatar">
          <img :src="user.avatar" style="width: 150px; height: 150px; border-radius: 50%" />
        </b-field>

        <!-- END     
    -->
        <b-field horizontal label="   ">
          <b-button type="is-info is-outlined" @click="save" :loading="posting" :disabled="posting"
            >Save changes</b-button
          >
        </b-field>

        <hr />
      </div>
    </div>
  </div>
</template>

<script>
import Editor from "@/components/e/EditorV";

export default {
  //name: "readmeEditor",
  components: {
    //Editor,
  },
  props: {
    user: {
      type: Object,
      default: null,
    },
    posting: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // readmeJson: defaultReadme, // "432432432",
    };
  },
  methods: {
    save() {
      this.$emit("save");
    },
  },
};
</script>

<style></style>
